import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
// import WaitingList from './WaitingList';

const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

const Overlay = styled.div`
  width: 100%;
  text-align: center;
  margin: 0px auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const BgImage = styled(Img)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
  height: ${(props) => props.height || '80vh'};
  // Adjust image positioning (if image covers area with defined height) and add font-family for polyfill
  & > img {
    object-fit: ${(props) => props.fit || 'cover'} !important;
    object-position: ${(props) => props.position || '50% 50%'} !important;
    font-family: 'object-fit: ${(props) => props.fit || 'cover'} !important; object-position: ${(
  props
) => props.position || '50% 50%'} !important;'
  }
`;

function Hero(props) {
  const { image, title, subtitle, link } = props;

  const overlay = (
    <Overlay>
      <div className="columns image-overlay is-vcentered">
        <div className="column is-1"></div>
        <div className={link ? 'column is-5' : 'column is-10'}>
          <h1
            className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen is-family-secondary has-text-left has-text-white"
            style={{
              lineHeight: '1',
            }}
          >
            {title}
          </h1>
          <h3
            className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen has-text-left has-text-white"
            style={{
              paddingTop: '2rem',
            }}
          >
            {subtitle}
          </h3>
        </div>
        {link && (
          <div className="column is-1 has-text-centered">
            {/* <WaitingList large location={'Hero'} url={'/'} /> */}
            <Link className="button is-primary is-large" to="/signup">
              Join Today!
            </Link>
          </div>
        )}
      </div>
    </Overlay>
  );
  return (
    <Container>
      {image.url ? (
        <div
          style={{
            backgroundImage: `url(${image})`,
            backgroundPosition: `top`,
            backgroundSize: 'cover',
          }}
        >
          <div
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
          >
            {overlay}
          </div>
        </div>
      ) : (
        <>
          <BgImage fluid={image} />
          {overlay}
        </>
      )}
    </Container>
  );
}

export default Hero;
